h1,
h2 {
  font-size: 45px;
  text-align: center;
}

.app {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

/* Tablet */

@media only screen and (min-device-width: 768px) and (max-device-width: 1388px) and (-webkit-min-device-pixel-ratio: 1) {
  .app {
    width: 90%;
  }
}

/* Smartphone */

@media only screen and (max-width: 767px) {
  .app {
    width: 100%;
  }
}
