.imageTop {
  display: flex;
  justify-content: center;
  object-fit: contain;
}

.imageTop img {
  max-width: 100%;
  max-height: 100%;
}

@media only screen and (max-width: 650px) { 
  .imageTop {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
