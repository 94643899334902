.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  min-height: 75px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #234f86;
}

.leftSide {
  flex: 9;
  text-align: center;
}

.link {
  display: flex;
align-items: center;}

/* .navbar .leftSide #hidden {
  display: flex;
} */

.navbar .leftSide .links a {
  text-decoration: none;
  color: white;
  font-size: 22px;
  margin-left: 2.1rem;
}

/* .navbar .leftSide .links a:hover,
.navbar .leftSide .links a:active,
.navbar .leftSide .links a.active {
  color: red;
}  */

.navbar .leftSide .links a:first-child:hover,
.navbar .leftSide .links a:first-child:active,
.navbar .leftSide .links a:first-child.active {
  color: #c3683c;
}

.navbar .leftSide .links a:nth-child(2):hover,
.navbar .leftSide .links a:nth-child(2):active,
.navbar .leftSide .links a:nth-child(2).active {
  color: #e5cf41;
}

.navbar .leftSide .links a:nth-child(3):hover,
.navbar .leftSide .links a:nth-child(3):active,
.navbar .leftSide .links a:nth-child(3).active {
  color: #3db02f;
}

.navbar .leftSide .links a:nth-child(4):hover,
.navbar .leftSide .links a:nth-child(4):active,
.navbar .leftSide .links a:nth-child(4).active {
  color: #55ccea;
}

.navbar .leftSide .links a:last-child:hover,
.navbar .leftSide .links a:last-child:active,
.navbar .leftSide .links a:last-child.active {
  color: #a91be0;
}

.navbarBurger {
  width: 40px;
  height: 40px;
  background: transparent;
  border: none;
  display: none;
}

.navbar .rightSide {
  flex: 3;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 25px;
}

.navbar .rightSide img {
  cursor: pointer;
}

  /* TABLET  

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1388px) 
  and (-webkit-min-device-pixel-ratio: 1) { 

  .link  {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .link a {
    margin-top: 0.3rem;
  }
} */

/*   SMARTPHONE   */

@media only screen 
  and (max-device-width: 950px) 
  and (-webkit-min-device-pixel-ratio: 1) {
  body {
    height: 100%;
 }

 .no-scroll {
  overflow: hidden;
}

  .link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    font-size: 15px;
    width: 0;
    top: 11.7vh;
    right: 0;
    height: 50vh;
    left: -80vw;
    margin:0;
    padding: 0;
    visibility: hidden;
    background-color: black;
    transition: all 0.8s ease-in-out;
  }

  .showNav .link {
    width: 100vw;
    left: 0;
    visibility: visible;
  }

  .navbar .leftSide .links a {
    margin-bottom: 1.5rem;
    margin-left: 0;
  }

  .link a::after {
    content: "";
    display: block;
    margin: 0 auto;
    width: 3vw;
    height: 1px;
    background: red;
  }

  .link a:last-child::after {
    display: none;
  }

  .link a {
    transform: translateY(100vh);
  }

  .showNav .link a {
    transform: translateY(0);
  }

  .navbar .leftSide .links a:first-child {
    transition: all 1s ease-out;    
  }

  .navbar .leftSide .links a:nth-child(2) {
    transition: all 1.1s ease-out;
  }

  .navbar .leftSide .links a:nth-child(3) {
    transition: all 1.2s ease-out;
  }

  .navbar .leftSide .links a:nth-child(4) {
    transition: all 1.3s ease-out;
  }

  .navbar .leftSide .links a:last-child {
    transition: all 1.4s ease-out;
  }

  .active {
    display: block;
    padding: 1.5rem;
    font-size: 5vw;
  }

  .navbarBurger {
    display: block;
    top: 1rem;
    left: 1rem;
  }

  .navbarBurger:hover {
    cursor: pointer;
  }

  .burgerBar,
  .burgerBar::before,
  .burgerBar::after {
    display: block;
    width: 40px;
    height: 3px;
    position: relative;
    border-radius: 3px;
    background: white;
    transition: all 0.5s ease-in-out;
  }

  .burgerBar::before,
  .burgerBar::after {
    content: "";
    position: absolute;
    left: 0;
  }

  .burgerBar::before {
    transform: translateY(-12px);
  }

  .burgerBar::after {
    transform: translateY(12px);
  }

  .showNav .burgerBar {
    width: 0;
    background: transparent;
  }

  .showNav .burgerBar::before {
    transform: rotate(45deg);
  }

  .showNav .burgerBar::after {
    transform: rotate(-45deg);
  }
}
