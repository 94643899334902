.footerLeft {
  display: flex;
  flex: 50%;
  max-height: 100px;
  max-width: 100px;
  margin-left: 10%;
  margin-top: 3%;
}

.footerLeft img {
  max-height: 100%;
  max-width: 100%;
  border-radius: 50px;
}


/* Tablet */
@media only screen and (max-width: 768px) and (max-width: 1388px) {
}

/* Smartphone */

@media only screen and (max-width: 767px) {
  .footerLeft {
    margin-left: auto;
    margin-right: auto;    
  }
}