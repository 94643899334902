.footerContent {
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 50%;
  margin-top: 3.5%;
  margin-left: -15%;
}

.footerContentTitle {
  font-size: 28px;
}

.footerContentList {
  font-size: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.footerContentList ul {
  padding: 0;
}

@media only screen and (max-width: 650px) {
  .footerContent{
    margin-left: 0;
  }
}